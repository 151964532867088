footer.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 25px;
    background-color: #FFF;
    box-shadow: var(--shadow);
}

footer p {
    text-align: center;
    font-size: 15px;
    margin-top: 10px;
}


/*Tablet*/
@media only screen and (max-width: 768px) {
    .footer {
        width: 100%;
    }
}


/*Celular*/
@media only screen and (max-width: 576px) {
    .footer {
        width: 100%;
    }
}
