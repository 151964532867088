.dt-nasc {
    width: 100%;
  }

  .image{
    margin-left: 1em;
    margin-bottom: 1em;
    border-radius: 50%;
    object-fit: cover;
  }

  .label-avatar{
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  
  }
  
  .label-avatar input{
    display: none;
  }
  
  .label-avatar span{
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
  }
  
  .label-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
  }

  .label-avatar2{
    width: 120px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .label-avatar2 input{
    display: none;
  }
  
  .label-avatar2 span{
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
  }
  
  .label-avatar2 span:hover{
    opacity: 1;
    transform: scale(1.4);
  }

  #botao label {
    margin-left: 10px;
    font-size: medium;
    font-weight: bold;
  }