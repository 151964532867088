aside.logo {
    background-color:  var(--bg-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

aside.logo img {
    padding:  0 15px;
    width: 90%;

}

@media(max-width: 576px){
    aside.logo img {
        width: 150px;
    }

    aside.logo {

        height: 50px;
    }

}