.menu-area {
    background-color: var(--bg-dark);
    box-shadow: 
    2px 0 10px 0 rgb(0, 0, 0, 0.12),
    2px 0 15px 0 rgb(0, 0, 0, 0.09) ;
}

.menu-desktop a {
    display: block;
    text-decoration: none;
    color: #FFF;
    font-weight: 200;
    padding: 10px;
}

.menu a:hover {
    background: linear-gradient(135deg, #07a7e3 0%, #32dac3 100%);
}

.menu .active {
    background: linear-gradient(135deg, #07a7e3 0%, #32dac3 100%);    
}

.menu-area .menu .menu-movel {
    margin-top: -800px;
}



@media only screen and (max-width: 768px){
    .menu {
        height: 100%;
        display: inline;
        justify-content: space-around;
        align-items: center;
        padding-top: 5px;
    }

    .menu a {
        line-height: 32px;
        text-align: center;
        display: inline;
        padding: 5px;
        margin: 0px;
    }
}

@media only screen and (max-width:576px) {
    .menu-desktop {
        display: none;
    }

    .menu-movel{
        line-height: 30px;
        text-align: center;
        display: inline;
        padding: 0px;

    }
    .menu-movel a{
        color: #FFF;
    }
}


