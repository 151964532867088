table{
  border:1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  overflow-y: scroll;
}

.tabela {
  display: inline-block;
  width: 100%;
  overflow: auto;
}

table caption{
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr{
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

tr.selected td {
  background-color: #333;
  color: #fff;    
}

table th,
table td{
  padding: .62em;
  text-align: center;
}

table th{
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

table td .action{
  border:0;
  padding: 5px;
  margin-right: 2px;
  align-items: center;
  display: inline-block;
  border-radius: 4px;;
}

table td .action svg{
  vertical-align: middle;
}

table td .badge{
  padding: 3px;
  border-radius: 3px;
  color: #FFF;
}
input {
margin-left: 5px;
}


@media screen and (max-width: 768px){
     table{
      border: 0;
    }
  
    table caption{
      font-size: 1.3em;
    }
  
    table thead{
      border:none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  
    table tr{
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .65em;
    }
  
    table td{
      border-bottom: 1px solid #DDD;
      display: block;
      font-size: .8em;
      text-align: right;
    }
  
    table td::before{
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
  
    table td:last-child{
      border-bottom: 0;
    }
  
}


.modalPesquisa{
 
    position: fixed;
    top: 0;
    bottom:0;
    right: 0;
    left: 0;
    background: rgba(0,0,0, 0.6);
    z-index: 99;

  }
  
  .modalPesquisa .containerPesquisa{
    display: block;
    position: fixed;
    max-width: 600px;
    max-height: 550px;
    top: 15%;
    left:0;
    right: 0;
    margin: 0 auto;
    padding: 4em 2rem;
    background-color: #FFF;
    box-shadow: 0 0 20px rgba(0,0,0, 0.8);
    overflow: auto
  }
  
  .closePesquisa{
    background-color: #F65835;
    border:0;
    color: #FFF;
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    border-radius: 5px;
  }
  
  .closePesquisa svg{
    margin-right: 5px;
  }
  
  .modalPesquisa h2{
    margin-bottom: 1.2em;
    font-size: 2em;
  }
  
  .modalPesquisa span{
    font-weight: bold;
    font-size: 1.2em;
    color: #121212;
  }
  
  .modalPesquisa span i{
    font-weight: 400;
    margin-right: 1em;
    padding: 2px 8px;
    border-radius: 3px;
  }
  
  .row1{
    margin-bottom: 1em;
  }
  
  .modalPesquisa p{
    padding-top: 0.5em;
    white-space: pre-wrap;
  }