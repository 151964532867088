
.tabela2 {
    display: inline-block;
    width:35%;
    overflow: auto;
  }

  table .row2{
    border: 0;
  }

  table    caption .row2{
    font-size: 1.0em;
  }


  table   thead .row2{
    border:none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table  tr .row2{
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .65em;
  }

  table .row2 td   {
    border-bottom: 1px solid #DDD;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table .row2 td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    font-size: 1.0em;
    text-transform: uppercase;
  }

  table .row2 td:last-child{
    border-bottom: 0;
  }
