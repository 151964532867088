header .item{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

header.header {
    background-color: #fff;
    padding: 2px 15px;

    white-space: nowrap;
    box-shadow: var(--shadow);
    width: 100%;
}

header.header h1 {
    font-size: 1.8em;
}

.imagemDeFundo{
    max-width: 100%;
    max-height: 100%;
    align-content: center;
}